    <template>
    <section v-if="dataLoading" class="about py-lg-9">
        <div class="text-center pt-7 pb-7">
            <loading-animation class="text-primary text-5x"/>
        </div>
    </section>
    <div v-else class="bg-4">
        <div class="container pt-7 pb-6">
            <div class="row" v-if="details.length!==0">

                <div class="col-12">
                    <heading-with-info class="" :bottom-tag="false"
                                       :title="mediaType === 'news' ? 'Latest News' : 'Blog'"/>
                </div>

                <div class="col-12">
                    <tabs color="primary" ref="cTabOne" align="center" class="c-tabs-1 m-0">
                        <tab title="All">
                            <div class="row">
                                <div v-for="(item,i) in details" :key="i" class="col-12 col-lg-4 col-md-6">
                                    <news-card
                                        :id="item.id"
                                        :mediaType="mediaType"
                                        :hour="item.created_at"
                                        :heading="item.title"
                                        :content="item.full_description.substring(0, 140)+' ...'"
                                        :img="item.image"
                                        :categ="item.category"
                                    ></news-card>
                                </div>
                            </div>
                        </tab>

                        <tab v-for="(categ,index) in category" :key="index" :title="categ">
                            <div class="row">
                                <template v-for="(item, i) in details">
                                    <div :key="i" class="col-12 col-lg-4 col-md-6" v-if="item.category===categ">
                                        <news-card
                                            :id="item.id"
                                            :mediaType="mediaType"
                                            :hour="item.created_at"
                                            :heading="item.title"
                                            :content="item.full_description.substring(0, 140)+' ...'"
                                            :img="item.image"
                                            :categ="item.category"
                                        ></news-card>
                                    </div>
                                </template>
                            </div>
                        </tab>
                    </tabs>

                </div>

            </div>

            <div v-else class="row">
                <div class="col-12">
                    <heading-with-info class="" :bottom-tag="false"
                                       :title="mediaType === 'news' ? 'No news today' : 'No blogs currently'"/>
                </div>
            </div>
        </div>
        <section id="premiumMembership">
            <premium-membership-section></premium-membership-section>
        </section>
    </div>
</template>

<script>
import HeadingWithInfo from '../../components/HeadingWithInfo';
import NewsCard from '../../components/home/NewsCard';
import axios from 'secure-axios';
import urls from '../../data/urls';
import PremiumMembershipSection from '../../components/home/PremiumMembershipSection';

export default {
    name: 'AllMediaPage',
    components: { PremiumMembershipSection, NewsCard, HeadingWithInfo },
    data () {
        return {
            mediaType: this.$route.params.media_type,
            category: [],
            details: '',
            dataLoading: false
        };
    },
    beforeMount () {
        this.loadDetails();
    },
    watch: {
        '$route.params.media_type': {
            handler () {
                this.mediaType = this.$route.params.media_type;
                this.loadDetails();
            }
        }
    },
    methods: {
        async loadDetails () {
            this.dataLoading = true;
            const response = await axios.get(urls.website.media, {
                params: {
                    media_type: this.mediaType
                }
            });
            const json = response.data;
            if (json.error === false) {
                this.details = json.data;
                this.setCategory();
            } else {
                this.$notify('Could not load data, Please try again later', 'Error', {
                    type: 'danger'
                });
            }
            this.dataLoading = false;
        },
        setCategory () {
            this.category = [];
            for (let i = 0; i < this.details.length; i++) {
                if (this.category.indexOf(this.details[i].category) === -1) {
                    this.category.push(this.details[i].category);
                }
            }
        }
    }
};
</script>

<style scoped>

</style>
